import * as React from 'react'

export interface ContentNode {
  id: string
  titleHtml: string
  contentHtml: string
  children: ContentNode[]
}

interface Props {
  contentNode: ContentNode | null
}

const renderChildren = (contentNode: ContentNode) => {
  if (!contentNode.children) {
    return null
  }
  const renderedChildren = contentNode.children.map((childContentNode, index) => {
    return (
      <ContentPanel
        key={childContentNode.id}
        contentNode={childContentNode}
      />
    )
  })
  return <>{renderedChildren}</>
}

export const ContentPanel = ({contentNode}: Props) => {
  if (!contentNode) {
    return null
  }
  return (
    <div>
      {contentNode.titleHtml && <div dangerouslySetInnerHTML={{__html: contentNode.titleHtml}} />}
      {contentNode.contentHtml && <div dangerouslySetInnerHTML={{__html: contentNode.contentHtml}} />}
      {renderChildren(contentNode)}
    </div>
  )
}