import * as React from 'react'

export enum AngArb {
  ANG = 'ANG',
  ARB = 'ARB',
}

export enum LinkType {
  KV_DOCSET = 'KV_DOCSET',
  KV_TIME_SLICE = 'KV_TIME_SLICE',
  KV_PARAGRAPH = 'KV_PARAGRAPH',
}

export interface Link {
  set: string
  provider: string
  type: LinkType
  id: string
  name: string
  url?: string
}
  

export interface BaseStructureNode<S> {
  link: Link
  children?: S[]
  hits?: number
}

export interface DocsetStructureNode extends BaseStructureNode<DocsetStructureNode> {
  validFrom: Date
  amended?: Date
  noContent?: boolean
  angArb?: AngArb[]
}
  
export interface DocsetStructure {
  main: DocsetStructureNode
  family?: DocsetStructureNode
}

interface Props {
  structure: DocsetStructure
}

const renderNode = (node: DocsetStructureNode) => {
  return (
    <>
      <li>{node.link.name}</li>
      {node.children &&
        <ul>
          {node.children.map(renderNode)}
        </ul>
      }
    </>
  )
}

export const StructurePanel = ({structure}: Props) => {
  return (
    <>
      <h3>Main structure</h3>
      <ul>
        {renderNode(structure.main)}
      </ul>
      {structure.family && 
      <>
        <h3>Family structure</h3>
        <ul>
          {renderNode(structure.family)}
        </ul>
      </>
      }
    </>
  )
}