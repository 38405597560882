import * as React from 'react'
import { ContentNode, ContentPanel } from './ContentPanel'
import './App.css'
import { DocsetStructure, StructurePanel } from './StructurePanel'

const SEARCH_ENDPOINT = 'https://digital.dev-alpha.oegbv.test.indoqa.com/api/v1/aem/search?query=corona&page=0&page-size=10'
const KV_DOCUMENT_ENDPOINT = 'https://digital.dev-alpha.oegbv.test.indoqa.com/api/v1/aem/kv-documents/4194207_20170101'
const KV_DOKSET_ENDPOINT = 'https://digital.dev-alpha.oegbv.test.indoqa.com/api/v1/aem/kv-structures/SI-2225_de'

interface Result {
  queryTime: number,
}

function App() {
  const [result, setResult] = React.useState<Result | null>(null)
  const [kvDocument, setKvDocument] = React.useState<ContentNode | null>(null)
  const [docset, setDocset] = React.useState<DocsetStructure | null>(null)

  const search = () => {
    fetch(SEARCH_ENDPOINT)
      .then<Result>((response) => response.json())
      .then((result) => setResult(result))
      .catch((e) => {
        console.log('An error occured.', e)
      })
  }

  const loadKvDocument = () => {
    fetch(KV_DOCUMENT_ENDPOINT)
      .then<ContentNode>((response) => response.json())
      .then((result) => setKvDocument(result))
      .catch((e) => {
        console.log('An error occured.', e)
      })
  }

  const loadDocsetStructure = () => {
    fetch(KV_DOKSET_ENDPOINT)
      .then<DocsetStructure>((response) => response.json())
      .then((result) => setDocset(result))
      .catch((e) => {
        console.log('An error occured.', e)
      })
  }

  return (
    <div className="App">
      <div>
        <div>
          <button onClick={() => search()}>Execute AEM query</button>
        </div>
        {result ? `The query was executed in ${result.queryTime}ms.` : ''}
      </div>

      <div style={{marginTop: '30px'}}>
        <div>
          <button onClick={() => loadKvDocument()}>Load KV document</button>
        </div>
      </div>
      {kvDocument && 
        <div className="digital">
          <ContentPanel contentNode={kvDocument} />
        </div>
      }

      <div style={{marginTop: '30px'}}>
        <div>
          <button onClick={() => loadDocsetStructure()}>Load KV docset structure</button>
        </div>
      </div>
      {docset && 
        <div>
          <StructurePanel structure={docset} />
        </div>
      }
    </div>
  )
}

export default App;
